<template>
  <div v-if="item.filler" class="item filler" />
  <div
    v-else
    class="item"
    :style="{'--color': color}"
    :class="{selected: item.selected}"
    @click="onClick"
  >
    <v-img
      :transition="false"
      :aspect-ratio="1"
      :src="`https://community.cloudflare.steamstatic.com/economy/image/${item.image}/164x164`"
      contain
    />
    <div class="inner">
      <!-- item attributes -->
      <div v-if="item.price" class="price lh-1 font-weight-bold">
        ${{ item.price | toScrap }}
      </div>
      <div v-else class="price primary300--text lh-1 text-caption font-weight-bold">
        no price
      </div>

      <div v-if="item.isNew" class="new-banner green700 green200--text">
        NEW
      </div>

      <!-- hover overlay -->
      <div class="meta">
        <div class="item-info">
          <div
            :style="{'color': color}"
            class="name font-weight-bold"
          >
            {{ item.name | truncate(50) }}
          </div>
        </div>
      </div>

      <div v-if="flags.length" class="item-flags text-caption d-flex align-center justify-end py-2 px-1">
        <v-tooltip
          v-for="flag in flags" :key="flag.tooltip"
          top open-delay="300"
          content-class="rounded-0"
          color="grey700"
          transition="v-scale-sorta"
        >
          <template #activator="{on}">
            <fai
              class="item-flag"
              :icon="flag.icon"
              :class="[flag.classes]"
              fixed-width
              v-on="on"
            />
          </template>
          <span v-t="flag.tooltip" class="text-caption" />
        </v-tooltip>

        <!-- withdrawable -->
        <v-tooltip
          v-if="item.canWithdraw"
          top open-delay="300" content-class="rounded-0" color="grey700"
          transition="v-scale-sorta"
        >
          <template #activator="{on}">
            <fai
              class="item-flag"
              :icon="['fad', 'arrow-alt-square-up']"
              :class="{
                'green400--text': item.inStock,
                'primary400--text': !item.inStock,
              }"
              fixed-width
              v-on="on"
            />
          </template>
          <span v-text="item.inStock ? 'Withdrawable & in stock': 'Withdrawable but not in stock'">Tooltip</span>
        </v-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
const FLAGS = {
  // canTip: {
  //   tooltip: 'common.item.can_tip',
  //   icon: ['fad', 'hand-holding-heart'],
  //   classes: 'green400--text',
  // },
  canSell: {
    tooltip: 'common.item.can_sell',
    icon: ['fad', 'coins'],
    classes: 'blue300--text',
  },
  // canWithdraw: {
  //   tooltip: 'common.item.can_withdraw',
  //   icon: ['fad', 'arrow-alt-square-up'],
  //   classes: 'text--secondary',
  // },
  cannotBet: {
    tooltip: 'common.item.cannot_bet',
    icon: ['fad', 'ban'],
    classes: 'primary400--text',
  },
}

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    item() {
      return this.data
    },
    color() {
      const { item } = this
      return item.colors?.name ?? item.color
    },
    flags() {
      const { item } = this
      return Object.entries(FLAGS).filter(([k, v]) => {
        if (k === 'canSell') return item.isSellable
        if (k === 'cannotBet') return !item.canBet

        return !!item[k]
      }).map(([, v]) => v)
    },
  },
  methods: {
    onClick() {
      this.$emit('onClick', this.item)
    },
  },
}
</script>

<style lang="scss" scoped>
$panelbg: rgba(grey(300), 0.75);

.item {
  position: relative;
  background-color: rgba($panelbg, 0.7);
  cursor: pointer;
  flex: 1;

  &::v-deep .v-image {
    position: relative;
    z-index: 2;
    margin: 10px;
  }

  .inner {
    overflow: hidden;
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .price {
      position: absolute;
      top: 0;
      left: 0;
      padding: 8px 32px 20px 8px;
      background: radial-gradient(ellipse at top left, rgba(grey(600), 0.5), transparent 70%);
      font-size: 14px;
    }

    .new-banner {
      position: absolute;
      top: 0;
      right: 0;
      padding: 4px 35px 5px 35px;
      font-size: 10px;
      font-weight: 900;
      line-height: 1;
      transform: rotate(35deg) translate(25%, -40%);
    }
  }

  .meta {
    display: flex;
    flex-direction: column-reverse;
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 8px;
    opacity: 0;
    background: rgba(grey(900), 0.8);
    transition: opacity 0.3s ease;
    justify-content: center;
    will-change: opacity;

    .item-info {
      width: 100%;
      opacity: 0;
      color: white;
      // font-family: $family-primary;
      font-size: 14px;
      font-weight: 600;
      text-align: center;
      user-select: none;
      transition: transform 0.3s ease, opacity 0.3s ease;
      transform: translateY(40px);
    }

    &:hover {
      opacity: 1;

      .item-info {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  .item-flags {
    overflow: hidden;
    position: absolute;
    z-index: 5;
    bottom: 0;
    left: 0;
    width: 100%;
    background: radial-gradient(ellipse at bottom right, rgba(grey(600), 0.5), transparent 70%);

    .item-flag:not(:last-of-type) {
      margin-right: 1px;
    }
  }

  &.filler {
    // visibility: hidden;
    background-color: rgba($panelbg, 0.7);
    cursor: default;
  }

  &.disabled {
    cursor: no-drop;
  }

  &.selected {
    background-color: darken(blue(), 7%);

    &::before,
    &::after {
      display: none;
    }
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &::before {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.35;
    background: radial-gradient(var(--color), transparent);
    content: ' ';
  }
}
</style>
