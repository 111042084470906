<template>
  <RecycleScroller
    v-if="!!itemRows.length && !loading"
    v-slot="{item}"
    ref="inventory"
    class="inventory"
    :items="itemRows"
    :item-size="rowHeight"
    :min-item-size="minItemSize"
    :style="{
      '--min-item-size': `${minItemSize}px`,
      '--item-gap': `${itemGap}px`,
    }"
    @resize="onResize"
  >
    <div class="item-row">
      <component
        :is="itemComp"
        v-for="i in item.items" :key="i.id"
        v-bind="itemProps"
        :data="i"
        :dollar-value="dollarValue"
        @onItemAmountChange="onItemAmountChange"
        @onClick="onClick"
      />
    </div>
  </RecycleScroller>

  <div v-else-if="!loading" class="no-items-container">
    <slot name="no-items">
      <div class="inventory no-items">
        <span class="title text-uppercase">{{ $t('inventory.no_items') }}</span>
      </div>
    </slot>
  </div>

  <div v-else class="loaders d-flex align-center justify-center flex-wrap mx-n1">
    <div class="loading-text d-flex justify-center">
      <v-progress-circular class="mr-4" color="primary" indeterminate size="32" />
      <h2 class="title">
        Loading
      </h2>
    </div>
    <v-skeleton-loader
      v-for="i in 24"
      :key="i"
      tile
      type="image"
      height="120"
      width="120"
      class="mx-1 mb-2 flex-grow-1"
    />
    <!-- :width="(Math.random() * (30 - 10) + 10) + '%'" -->
  </div>
</template>

<script>
import { RecycleScroller } from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import { chunk, debounce } from '@/utils'

// FIXME resize event doesnt fire consistently when multiple inventories on page

export default {
  name: 'ComponentInventory',
  components: {
    RecycleScroller,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    dollarValue: {
      type: Boolean,
      default: false,
    },
    minItemSize: {
      type: Number,
      default: 120,
    },
    itemGap: {
      type: Number,
      default: 1,
    },
    itemComponent: {
      type: Object,
      default: null,
    },
    itemProps: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      itemsPerRow: 8,
      rowHeight: this.minItemSize,
    }
  },
  computed: {
    itemRows() {
      if (!this.items.length) return []

      const chunks = chunk(this.items, this.itemsPerRow)

      // fill last row with filler items
      const lastChunk = chunks[chunks.length - 1]

      if (lastChunk.length < this.itemsPerRow) {
        while (lastChunk.length < this.itemsPerRow) {
          lastChunk.push({
            filler: true,
          })
        }
      }

      return chunks.map((set, i) => ({
        id: `chunk--${i}`,
        items: set,
      }))
    },
    itemComp() {
      return this.itemComponent ?? Item
    },
  },
  watch: {
    items(val, old) {
      if (val && !old?.length) {
        this.onResize()
      }
    },
  },
  mounted() {
    this.onResize()
  },
  methods: {
    onResize: debounce(function onResize() {
      if (!this.items.length) return
      if (!this.$refs.inventory) return

      const { minItemSize, itemGap } = this

      // get inventory width without padding, border or margin
      const invWidth = this.$refs.inventory.$el.clientWidth
      if (!invWidth) return

      let itemsPerRow = Math.floor(invWidth / minItemSize)

      // items only have gap between eachother, so we -1 to remove last edge
      const totalItemGap = Math.max(2, itemsPerRow - 1) * itemGap

      // if items + gap > available space, remove an item
      const isOverflowing = (itemsPerRow * minItemSize) + totalItemGap > invWidth
      if (isOverflowing) itemsPerRow -= 1

      this.itemsPerRow = Math.max(1, itemsPerRow)

      const itemSize = minItemSize + (invWidth - itemsPerRow * minItemSize) / itemsPerRow
      this.rowHeight = itemSize
    }, 50),
    // events
    onClick(item) {
      this.$emit('onItemClick', item)
    },
    onItemAmountChange(item, val) {
      this.$emit('onItemAmountChange', item, val)
    },
  },
}

</script>

<style lang="scss" scoped>

$panelbg: rgba(grey(300), 0.75);

::v-deep .v-skeleton-loader__image {
  background: $panelbg !important;
}

.loaders {
  overflow: hidden;
  position: relative;
  height: 124 * 2px;

  .loading-text {
    position: absolute;
    z-index: 3;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

// NOTE do NOT give this element padding
.inventory {
  overflow-y: scroll !important;
  height: 100%;
  will-change: transform;

  &::-webkit-scrollbar {
    width: 14px !important;
    background: none !important;
  }

  &::-webkit-scrollbar-thumb {
    min-height: 10%;
    // border-right: 4px solid rgba(0, 0, 0, 0);
    border-left: 6px solid rgba(0, 0, 0, 0);
    background-color: grey(300) !important;
    background-clip: padding-box !important;
  }

  &::-webkit-scrollbar-thumb:active {
    background-color: primary(600) !important;
  }
}

.no-items-container {
  height: 100%;
}

.inventory.no-items {
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(grey(100), 0.3);
}

.item-row {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(var(--min-item-size), 1fr));
  grid-gap: var(--item-gap);
}
</style>
