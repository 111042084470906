var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.item.filler)?_c('div',{staticClass:"item filler"}):_c('div',{staticClass:"item",class:{selected: _vm.item.selected},style:({'--color': _vm.color}),on:{"click":_vm.onClick}},[_c('v-img',{attrs:{"transition":false,"aspect-ratio":1,"src":("https://community.cloudflare.steamstatic.com/economy/image/" + (_vm.item.image) + "/164x164"),"contain":""}}),_c('div',{staticClass:"inner"},[(_vm.item.price)?_c('div',{staticClass:"price lh-1 font-weight-bold"},[_vm._v(" $"+_vm._s(_vm._f("toScrap")(_vm.item.price))+" ")]):_c('div',{staticClass:"price primary300--text lh-1 text-caption font-weight-bold"},[_vm._v(" no price ")]),(_vm.item.isNew)?_c('div',{staticClass:"new-banner green700 green200--text"},[_vm._v(" NEW ")]):_vm._e(),_c('div',{staticClass:"meta"},[_c('div',{staticClass:"item-info"},[_c('div',{staticClass:"name font-weight-bold",style:({'color': _vm.color})},[_vm._v(" "+_vm._s(_vm._f("truncate")(_vm.item.name,50))+" ")])])]),(_vm.flags.length)?_c('div',{staticClass:"item-flags text-caption d-flex align-center justify-end py-2 px-1"},[_vm._l((_vm.flags),function(flag){return _c('v-tooltip',{key:flag.tooltip,attrs:{"top":"","open-delay":"300","content-class":"rounded-0","color":"grey700","transition":"v-scale-sorta"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('fai',_vm._g({staticClass:"item-flag",class:[flag.classes],attrs:{"icon":flag.icon,"fixed-width":""}},on))]}}],null,true)},[_c('span',{directives:[{name:"t",rawName:"v-t",value:(flag.tooltip),expression:"flag.tooltip"}],staticClass:"text-caption"})])}),(_vm.item.canWithdraw)?_c('v-tooltip',{attrs:{"top":"","open-delay":"300","content-class":"rounded-0","color":"grey700","transition":"v-scale-sorta"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('fai',_vm._g({staticClass:"item-flag",class:{
              'green400--text': _vm.item.inStock,
              'primary400--text': !_vm.item.inStock,
            },attrs:{"icon":['fad', 'arrow-alt-square-up'],"fixed-width":""}},on))]}}],null,false,3945509730)},[_c('span',{domProps:{"textContent":_vm._s(_vm.item.inStock ? 'Withdrawable & in stock': 'Withdrawable but not in stock')}},[_vm._v("Tooltip")])]):_vm._e()],2):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }