<template>
  <div>
    <div class="give-items d-flex mb-5">
      <div class="shop d-flex flex-column flex-grow-1 mr-3">
        <div class="d-flex mb-3">
          <!-- search -->
          <v-text-field
            :value="inputs.search"
            class="mr-2 rounded-0 search-input"
            background-color="grey400"
            :placeholder="$t('common.search')"
            hide-details solo flat dense
            @input="onSearchInput"
          />
        </div>

        <Inventory
          class="flex-grow-1"
          :items="filteredItems"
          :item-component="itemComponent"
          :min-item-size="90"
          @onItemClick="onItemClick"
        />
      </div>

      <!-- ree -->
      <div class="cart grey800 d-flex flex-column">
        <div class="cart-items flex-grow-1 mb-3">
          <div
            v-for="item in selectedItems" :key="item.id"
            class="cart-item grey600 d-flex align-center mb-1 px-2 py-3"
          >
            <div class="grey700 pos-relative remove-container pa-2 flex-grow-0 mr-3">
              <v-img
                :src="`https://community.cloudflare.steamstatic.com/economy/image/${item.image}/164x164`"
                aspect-ratio="1" width="40"
              />

              <a class="pa-4 d-flex align-center justify-center flex-column remove-btn" @click="onItemRemove(item)">
                <fai class="primary100--text text-h5 mb-1" :icon="['far', 'times-circle']" />
              </a>
            </div>
            <div class="grey100--text text-caption">
              <div class="lh-1">
                {{ item.name }}
              </div>
              <div class="blue300--text font-weight-semibold">
                ${{ item.price | toScrap }} - {{ item.weight / totalWeight * 100 | toLocale(6) }}%
              </div>
              <input
                v-model.number="item.weight"
                placeholder="Weight"
                class="grey900 grey100--text weight-input"
                type="number"
              >
            </div>
          </div>
        </div>

        <v-btn
          class="flex-grow-0"
          color="green700 green200--text"
          tile depressed block
          :ripple="false"
          @click="onInsert"
        >
          Insert
        </v-btn>
      </div>
    </div>

    <!--  -->
    <v-row>
      <v-col cols="4">
        <div class="flex-grow-0 full-height grey600 pa-3 mb-2">
          <h4 class="text-body-2">
            Desired case edge
          </h4>
          <p class="text-caption text--disabled mb-1">
            (0 for free cases)
          </p>
          <v-slider
            v-model="inputs.edge"
            hide-details
            dense
            track-color="grey200"
            tick-size="4"
            :min="0"
            :max="1"
            :step="0.01"
            track-fill-color="rgba(255,255,255,.8)"
          >
            <template #append>
              <div class="value-label">
                {{ inputs.edge * 100 | toLocale }}%
              </div>
            </template>
          </v-slider>
        </div>
      </v-col>

      <v-col cols="4">
        <div class="flex-grow-0 full-height grey600 pa-3 mb-2">
          <h4 class="text-body-2">
            Case price
          </h4>

          <v-currency-field
            v-model="inputs.price"
            :options="{ allowNegative: false }"
            placeholder="Desired case price"
            class="rounded-0 grey100--text text-caption"
            solo hide-details dense
            flat background-color="grey700"
          />
        </div>
      </v-col>

      <v-col cols="4">
        <v-btn
          class="flex-grow-1 mb-2"
          color="grey400 grey200--text"
          tile depressed block
          :ripple="false"
          @click="reset"
        >
          Reset
        </v-btn>
        <v-btn
          class="flex-grow-1 mb-2"
          color="grey400 grey200--text"
          tile depressed block
          :ripple="false"
          @click="onGenerateOdds"
        >
          Generate weights
        </v-btn>

        ER: {{ ER * 100 | toLocale(4) }}%
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { debounce } from '@/utils'

import Inventory from '@/components/Inventory'
import BackpackItem from '@/components/BackpackItem'

function canGenerateAtPrice(items, targetPrice) {
  for (let i = 0; i < items.length; i++) {
    if (items[i].price < targetPrice) return true
  }
  return false
}

// expects item {name, price, weight}
function calcER(items, targetPrice) {
  const totalWeight = items.reduce((acc, curr) => acc + curr.weight, 0)

  const itemsProba = items.map(el => ({
    price: el.price,
    weight: el.weight,
    name: el.name,
    probability: el.weight / totalWeight,
  }))

  return (itemsProba.reduce((acc, curr) => acc + curr.probability * curr.price, 0) - targetPrice) / targetPrice
}

function calcERDelta(items, targetPrice, edge) {
  // since edge is edge = -ER
  return calcER(items, targetPrice) + edge
}

// item here is {price, name, weight}
function tweakWeightsAbove(items, weightToAdd, targetPrice) {
  const itemsCpy = [...items]

  for (let i = 0; i < itemsCpy.length; i++) {
    if (itemsCpy[i].price > targetPrice) {
      itemsCpy[i].weight = Math.max(itemsCpy[i].weight + weightToAdd, 1)
    }
  }

  return itemsCpy
}

function tweakWeightsBelow(items, weightToAdd, targetPrice) {
  const itemsCpy = [...items]

  for (let i = 0; i < itemsCpy.length; i++) {
    if (itemsCpy[i].price <= targetPrice) {
      itemsCpy[i].weight = Math.max(itemsCpy[i].weight + weightToAdd, 1)
    }
  }

  return itemsCpy
}

// item: {name, price} targetPrice is an int
// NOTE if ERDelta < margin, then the edge of the case is too high, else edge too low
function generateOdds(items, targetPrice, edge, opts = {
  margin: 0.001,
  minPrice: 1,
  maxPrice: 1e5,
  maxIter: 2000,
  stepBase: 1,
}) {
  if (targetPrice < opts.minPrice || targetPrice > opts.maxPrice) throw new Error('Targetprice could not be found within reasonable range')
  if (items.length < 2) throw new Error('Cannot generate odds for item count < 2')
  if (!canGenerateAtPrice(items, targetPrice)) throw new Error('Cannot generate case odds for this price point')

  // sort the items in descending order by price
  let sortedItems = [...items].sort((a, b) => b.price - a.price)

  // initialize the weights by some measure that was picked beforehand
  const highestPrice = sortedItems[0].price

  // eslint-disable-next-line no-param-reassign
  sortedItems.forEach(el => { el.weight = Math.floor((5 * highestPrice) / el.price) })

  let ERDelta = calcERDelta(sortedItems, targetPrice, edge)
  let iter = 0

  // tweak the weights and price until the ER is close the the edge we gave as input
  while (Math.abs(ERDelta) >= opts.margin) {
    const multiplier = Math.floor(Math.abs(ERDelta) / opts.margin + 1)
    const step = opts.stepBase * multiplier

    // If after max iterations ER hasnt been reached, try tweaking the price (recursion is safe)
    if (iter > opts.maxIter) return generateOdds(items, targetPrice + Math.sign(ERDelta), edge)

    if (ERDelta < opts.margin) {
      sortedItems = tweakWeightsAbove(sortedItems, step, targetPrice)
    } else {
      sortedItems = tweakWeightsBelow(sortedItems, step, targetPrice)
    }

    ERDelta = calcERDelta(sortedItems, targetPrice, edge)
    iter++
  }

  return {
    price: targetPrice,
    items: sortedItems,
    ER: ERDelta - edge,
  }
}


export default {
  components: {
    Inventory,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    contents: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      inputs: {
        edge: null,
        price: null,
        search: '',
      },
      builderItems: [],
    }
  },
  computed: {
    // ...mapGetters({}),
    itemComponent() {
      return BackpackItem
    },
    filteredItems() {
      let items = [...this.builderItems]
      const { search } = this.inputs

      if (search) {
        items = items.filter(i => (i?.name ?? '').toLowerCase().includes(search.toLowerCase()))
      }

      return items
    },
    selectedItems() {
      return this.builderItems.filter(i => i.selected)
    },
    selectedValue() {
      return this.selectedItems.reduce((acc, i) => acc + i.price, 0)
    },
    totalWeight() {
      return this.selectedItems.reduce((acc, i) => acc + i.weight, 0)
    },
    ER() {
      return calcER(this.selectedItems, this.inputs.price)
    },
  },
  watch: {
    contents() {
      this.reset()
    },
  },
  created() {
    this.reset()
  },
  methods: {
    onItemClick(item) {
      // eslint-disable-next-line no-param-reassign
      item.selected = !item.selected
      // eslint-disable-next-line no-param-reassign
      item.weight = 0
    },
    onItemRemove(item) {
      // eslint-disable-next-line no-param-reassign
      item.selected = false
      // eslint-disable-next-line no-param-reassign
      item.weight = 0
    },
    onSearchInput: debounce(function onChange(search) {
      this.inputs.search = search.trim()
    }, 200),
    onGenerateOdds() {
      const items = [...this.selectedItems]

      try {
        const res = generateOdds(items.map(i => ({ ...i, price: i.price || 1 })), this.inputs.price, this.inputs.edge)
        this.inputs.price = res.price

        for (const item of res.items) {
          const itemIndex = this.selectedItems.findIndex(i => i.id === item.id)
          if (itemIndex !== -1) {
            this.selectedItems[itemIndex].weight = item.weight
          }
        }
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    onInsert() {
      this.$emit('insert', this.selectedItems.map(i => ({
        id: i.id,
        price: i.price,
        weight: i.weight,
      })))
    },
    reset() {
      this.builderItems = this.items.map(i => ({
        ...i,
        id: i.name,
        selected: false,
        weight: 0,
      }))

      this.builderItems.push({
        id: 'dud',
        name: 'dud',
        image: 'fWFc82js0fmoRAP-qOIPu5THSWqfSmTELLqcUywGkijVjZULUrsm1j-9xgEAaR4uURrwvz0N252yVaDVWrRTno9m4ccG2GNqxlQoZrC2aG9hcVGUWflbX_drrVu5UGki5sAij6tOtQ',
        price: 0,
        weight: 0,
        selected: false,
      })

      const { builderItems } = this

      if (this.contents.length) {
        for (const item of this.contents) {
          const itemIndex = this.builderItems.findIndex(i => i.id === item.id)
          if (itemIndex !== -1) {
            builderItems[itemIndex].selected = true
            builderItems[itemIndex].weight = item.weight
          }
        }
      }
    },
  },
}
</script>


<style lang="scss" scoped>
.give-items {
  height: 600px;

  .shop {
    overflow: hidden;
  }

  ::v-deep .item-flags {
    display: none !important;
  }

  .cart {
    min-width: 250px;
    max-width: 250px;

    .cart-items {
      overflow-y: auto;
    }

    .cart-item {
      .remove-btn {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        background: rgba(primary(500), 0.75);
        transition: opacity 0.3s ease, transform 0.3s ease;
        transform: scale(0.7);
      }

      .weight-input {
        width: 80%;
      }

      &:hover {
        .remove-btn {
          opacity: 1;
          transform: scale(1);
        }
      }
    }
  }
}
</style>
